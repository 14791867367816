@import '../../../assets/scss';
.otp-input {
  width: 40px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 0;
  border-radius: 8px;
  text-align: center;

  background-color: $colorInputFieldBackground;
  color: $colorModalText;
  border: 1px solid $colorAccent;

  &.custom-background {
    background-color: $colorInputFieldBackground;
    color: $colorInputText;
    border: 1px solid $colorInputFieldFrame;
  }

  box-shadow: 0 2px 4px rgba(0,0,0, 0.05);
}

.otp-input:hover {
  border-color: $colorAccent;
}

.otp-input:focus {
  border-color: $colorAccent;
  box-shadow: 0 0 0 3px $colorAccent;
}

/* firefox */
.otp-input:focus-visible {
  outline: 0;
}