@import '../../../assets/scss';

.modal-custom-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(#{$modalMaxWidth} / 2);
  max-height: $modalMaxHeight;
  @include overflowScroll();
  background-color: $colorModalBackground;
  color: $colorModalText;

  @include desktop {
    min-width: 35%;
  }

  .mobile-header {
    display: flex;
    align-items: center;
    height: $mobileHeaderHeightDefault;

    .back-button {
      .back-button-icon {
        font-size: $fontSizeMobileHeaderIcon;
        color: $colorLoginText1;
        padding-left: $paddingXContainerMobileDefault;
        @include mobile768 {
          padding-left: calc((100vw - #{$mainMobileWidth768}) / 2);
        }
      }

      &.invisible {
        visibility: hidden;
      }
    }
  }

  .modal-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $modalPadding;
    padding: $modalPadding;
    margin: 0 auto;
  }

  img.close {
    height: $modalCloseBtnHeight;
    width: $modalCloseBtnWidth;
    top: 10px;
    left: 10px;
    cursor: pointer;
    align-self: end;
    margin-bottom: -1.5rem;
  }

  .header {
    font-size: $fontSizeHeader4;
    font-weight: $fontWeightBold;
    margin-bottom: calc(#{$modalPadding} / 2);
    @include mobile {
      margin-top: 5%;
    }
  }

  .invisible-text {
    visibility: hidden;
  }

  .content-1 {
    margin-top: 6vh;
    text-align: center;
  }

  .content-2 {
    text-align: center;
  }

  button.cancel {
    @include removeButtonStyling();
    box-sizing: border-box;
    // padding: 5px; // for desktop skip button
    // margin: 12vh 0 2vh 0; // for desktop skip button
    @include mobile {
      position: fixed;
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include desktop {
    .confirm-button {
      margin-bottom: 12vh;
    }
  }

  @include mobile {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: none;
    max-height: none;
    background-color: $colorDefaultBackground;
    color: $colorLoginText1; // TODO ask how operators adding $colorLoginText
  }

  .center-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}