:root {
  --color-orange: #FF7C46;
  --color-grey-darker: #232423;
  --color-grey-dark: #323332;
  --color-grey: #636363;
  --color-grey-translucent: #63636360;
  --color-grey-light: #9E9D9E;
  --color-grey-very-light: #E2E2E2;
  --color-white: #FFFFFF;
  --color-white-transparent: #FFFFFF00;
  --color-black: #000000;
  --color-red: #fb3a2f;

  --accent-color: var(--color-orange);
  --default-background: var(--color-grey-dark);
  --login-background: var(--color-grey-dark);
  --login-text-color: var(--color-white);
  --login-navigation-color: var(--color-white);
  --input-field-background-color: var(--color-white-transparent);
  --input-field-frame-color: var(--color-white);
  --input-hint-color: var(--color-grey-light);
  --input-text-color: var(--color-white);
  --button-text-color-active: var(--color-white);
  --button-background-color-active: var(--color-orange);
  --button-text-color-inactive: var(--color-white);
  --button-background-color-inactive: var(--color-grey-light);
  --menu-background-colour: var(--color-grey-dark);
  --menu-text-colour: var(--color-orange);
  --menu-icon-colour: var(--color-orange);
  --menu-itinerary-background-colour: var(--color-grey-darker);
  --menu-divider-colour: var(--color-grey-light);

  --login-border-color: null;
  --icon-background-color: null;

  --login-text-1-colour: var(--color-white);
  --login-text-2-colour: var(--color-white);
  --login-text-3-colour: var(--color-white);
  --login-text-4-colour: var(--color-white);
  --login-text-5-colour: var(--color-white);

  --terms-background-color: var(--default-background);
  --terms-text-color: var(--color-white);
  --terms-headline-color: var(--color-white);

  --faq-background-color: var(--default-background);
  --faq-text-color: var(--color-white);
  --faq-headline-color: var(--color-white);

  --about-background-color: var(--default-background);
  --about-text-color: var(--color-white);
  --about-headline-color: var(--color-white);


  // NOTE: this won't exist for custom apps. They will just use --menu-text-colour
  // The default Vamoos app requires it to be #FFFFFF though. So if there's custom
  // app styling, this must be overridden with the custom app's --menu-text-colour
  --menu-itinerary-text-colour: #FFFFFF;
}

$colorOrange: var(--color-orange);
$colorGreyDarker: var(--color-grey-darker);
$colorGreyDark: var(--color-grey-dark);
$colorGrey: var(--color-grey);
$colorGreyLight: var(--color-grey-light);
$colorGreyVeryLight: var(--color-grey-very-light);
$colorWhite: var(--color-white);
$colorWhiteTransparent: var(--color-white-transparent);
$colorBlack: var(--color-black);
$colorRed: var(--color-red);

$colorPostTile: $colorWhite;

$filterButtonShadow: drop-shadow(0 0 10px $colorBlack);
$appGradient: to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2), transparent, transparent, transparent, transparent, rgba(0, 0, 0, 1);
$appGradientReverse: to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2), transparent, transparent, transparent, transparent, rgba(0, 0, 0, 1);

$colorText: $colorWhite;
$colorAccent: var(--accent-color);
$colorDefaultBackground: var(--default-background);
$colorAlert: $colorRed;

$colorLoginBackground: var(--login-background);
$colorLoginText: var(--login-text-color);
$colorLoginNavigation: var(--login-navigation-color);
$colorLoginBorder: var(--login-border-color);

$colorInputFieldBackground: var(--input-field-background-color);
$colorInputFieldFrame: var(--input-field-frame-color);
$colorInputText: var(--input-text-color);
$colorInputHint: var(--input-hint-color);

$colorButtonTextActive: var(--button-text-color-active);
$colorButtonBackgroundActive: var(--button-background-color-active);
$colorButtonTextInactive: var(--button-text-color-inactive);
$colorButtonBackgroundInactive: var(--button-background-color-inactive);

$colorMenuBackground: var(--menu-background-colour);
$colorMenuText: var(--menu-text-colour);
$colorMenuIcon: var(--menu-icon-colour);
$colorMenuItineraryBackground: var(--menu-itinerary-background-colour);
$colorMenuDivider: var(--menu-divider-colour);
$colorMenuItineraryText: var(--menu-itinerary-text-colour);

$colorLoginText1: var(--login-text-1-colour);
$colorLoginText2: var(--login-text-2-colour);
$colorLoginText3: var(--login-text-3-colour);
$colorLoginText4: var(--login-text-4-colour);
$colorLoginText5: var(--login-text-5-colour);

$colorIconBackground: var(--icon-background-color);

$colorModalBackground: $colorWhite;
$colorModalText: $colorBlack;
$colorModalHeadline: $colorBlack;

$colorTermsBackground: var(--terms-background-color);
$colorTermsText: var(--terms-text-color);
$colorTermsHeadline: var(--terms-headline-color);

$colorFaqBackground: var(--faq-background-color);
$colorFaqText: var(--faq-text-color);
$colorFaqHeadline: var(--faq-headline-color);

$colorAboutBackground: var(--about-background-color);
$colorAboutText: var(--about-text-color);
$colorAboutHeadline: var(--about-headline-color);

$colorListDivider: var(--color-grey-translucent);
