@import '../../assets/scss';

// NOTE padding is used instead of max width to allow dynamically adding/removing slides
// without seeing a transition.
$paddingX: calc((100vw - #{$mainDesktopWidthDefault}) / 2);

.login-slide {
  box-sizing: border-box;
  padding-top: 10vh;
  padding-left: $paddingX;
  padding-right: $paddingX;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  //width: $mainDesktopWidthDefault;
  //max-width: $mainDesktopMaxWidthDefault;
  margin: 0 auto;
  color: $colorLoginText;
  gap: 1vh;
  width: 100vw;
  overflow-y: auto;

  &.custom-template {
    gap: 3vh;

    @include mobile {
      gap: 2vh;
    }
  }

  .logo {
    max-height: $heightDesktopLogoDefault;
    max-width: 80vw;
  }

  .text-1 {
    color: $colorLoginText1;
    font-size: 1.7rem;

    &.custom-template {
      font-size: 1.4rem;
    }
  }

  .text-2 {
    color: $colorLoginText2;
    font-size: 1.3rem;

    &.custom-template {
      font-size: 1rem;
    }
  }

  .text-3 {
    color: $colorLoginText3;
    font-size: 1rem;
  }

  .text-4 {
    color: $colorLoginText4;
    font-size: 0.8rem;

    &.custom-template {
      font-size: 1rem;
    }
  }

  .text-5 {
    color: $colorLoginText5;
    font-size: 0.8rem;
    margin-top: 3vh;

    &.custom-template {
      font-size: 0.6rem;
      margin-top: -2vh;
      margin-bottom: 2vh;

      @include mobile {
        margin-top: -1vh;
      }
    }
  }

  .text-7 {
    font-size: 0.8rem;
    margin-top: auto;
  }

  button.link {
    @include removeButtonStyling();
    text-decoration: underline;
    display: inline;
    color: $colorAccent;
    &:focus {
      outline: none;
    }
  }

  .invisible-text {
    visibility: hidden;
  }

  .text-container-top {
    box-sizing: border-box;
    //padding: 2vh 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: auto;
    min-height: 35vh;
    max-width: 100%;

    &.custom-template {
      min-height: initial;
      justify-content: initial;
      gap: 2vh;
    }
  }

  .text-input {
    background-color: $colorInputFieldBackground;
    color: $colorInputText;
    border-color: $colorInputFieldFrame;
    width: 20vw;

    &::placeholder {
      color: $colorInputHint;
    }
  }

  button {
    background-color: $colorButtonBackgroundActive;
    color: $colorButtonTextActive;

    &[disabled] {
      background-color: $colorButtonBackgroundInactive;
      color: $colorButtonTextInactive;
    }

    &.skip {
      @include removeButtonStyling();
      //border: 1px solid white;
      box-sizing: border-box;
      padding: 5px;
      margin-top: 1vh;
    }
  }

  &.hidden {
    & > * {
      display: none;
    }
  }

  @include desktop1200 {
    //width: $mainDesktopWidth1200;
    $paddingX: calc((100vw - #{$mainDesktopWidth1200}) / 2);
    padding-left: $paddingX;
    padding-right: $paddingX;


    .logo {
      max-height: $heightDesktopLogo1200;
    }
  }

  @include desktop1400 {
    $paddingX: calc((100vw - #{$mainDesktopMaxWidthDefault}) / 2);
    padding-left: $paddingX;
    padding-right: $paddingX;
  }

  @include mobile {
    .logo {
      max-height: $heightMobileLogoDefault;
    }

    .text-input {
      width: 25vw;
    }
  }

  @include mobile768 {
    .logo {
      max-height: $heightMobileLogo768;
    }
    .text-input {
      width: 30vw;
    }
  }

  @include mobile540 {
    .logo {
      max-height: $heightMobileLogo768;
    }
    .text-input {
      width: 50vw;
    }
  }
}
