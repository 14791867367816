@import "./../../assets/scss";

.container-settings {
  main.settings {
    @include tileGrid();
    padding: $paddingYContainerMobilePreview $paddingXContainerMobileDefault;

    .settings-tile {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-content: space-between;
      background-color: $colorWhite;
      padding: calc( #{$paddingTileGrid} * 2 );
      gap: calc( #{$paddingTileGrid});

      height: auto;

      .button {
        &:active {
          opacity: 50%;
        }
      }

      .text-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $colorBlack;

        .title {
          font-size: $fontSizeSubHeading;
          padding-bottom: calc( #{$paddingTileGrid} * 2 );
        }

        .content {
          flex-basis: 50%;
          display: flex;
          align-items: center;
          @include clamp_lines(8, 1.2rem);
          span {
            display: block;
            margin-bottom: calc( #{$paddingTileGrid} / 2 );
          }
        }
      }

      .border-bottom {
        border-bottom: 1px solid $colorGreyVeryLight;
      }

      .emails {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: calc( #{$paddingTileGrid} / 2 );
        border-bottom: 1px solid $colorGreyVeryLight;
      }
      .center-button {
        //margin: 0 auto;
        //width: 50%;
        @include mobile {
          margin: 0 auto;
          width: 200px;
        }
      }

      .buttons {
        display: flex;
        padding-bottom: calc( #{$paddingTileGrid} );
        gap: calc( #{$paddingTileGrid} );

        @include mobile {
          justify-content: center;
          align-items: center;
          flex-direction: column;
          button {
            width: 200px;
          }
        }

        @include desktop {
          button {
            flex: 1;
          }
        }
      }
    }
  }

  &.mobile-view-generic {
    background-color: $colorDefaultBackground;
  }
}

.center-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
